import { format, isValid, parse, parseISO } from "date-fns";
import { ResponseActiveUsersData } from "../../../../types";
import { useEffect, useMemo, useState } from "react";

interface Props {
  data: ResponseActiveUsersData;
  selectedCountry: string[];
  setSelectedCountry: React.Dispatch<React.SetStateAction<string[]>>;
}

const LongCountryNames = {
  "United States": "US",
  "United Kingdom": "UK",
  "Saudi Arabia": "KSA",
  "European Union": "EU",
  "New Zealand": "NZ",
  "Hong Kong": "HK",
  "South Africa": "SA",
};

const UserTableBodyChild = ({ data, selectedCountry, setSelectedCountry }: Props) => {
  const [showAllOtherCountries, setShowAllOtherCountries] = useState<boolean>(false);

  const FilteredCountries = useMemo(() => {
    if (selectedCountry.length == 0) return data.target_countries;

    return [
      ...data.target_countries.filter((country) => selectedCountry.includes(country ?? "")),
      ...data.target_countries.filter((country) => !selectedCountry.includes(country ?? "")),
    ];
  }, [data, selectedCountry]);

  let formattedLastPostedDatetime, formattedJoinedDate;
  const parsedCommentDate = parseISO(data.last_posted_datetime ?? "");
  const parsedFormattedJoinedDate = parse(data.joined_date ?? "", "yyyy-MM-dd", new Date());
  if (isValid(parsedCommentDate)) {
    formattedLastPostedDatetime = format(parsedCommentDate, "dd MMM yyyy");
  }
  if (isValid(parsedFormattedJoinedDate)) {
    formattedJoinedDate = format(parsedFormattedJoinedDate, "dd MMM yyyy");
  }

  const handleCountryNumberClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.preventDefault();
    setShowAllOtherCountries(true);
  };

  const handleAddCountry = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.nativeEvent.preventDefault();

    const textVal = e.currentTarget.innerText;

    if (!selectedCountry.includes(textVal)) {
      setSelectedCountry((prev) => {
        return [...prev, textVal];
      });
    }
  };

  function encodeDomain(s: string) {
    let t = s.replace('.', '_')
    console.log(encodeURIComponent(t));
    return encodeURIComponent(t)
  }

  return (
    <a
      href={`/users/${data.username}/${data.forum_name}`}
      className='flex  rounded-[12px]  px-[15px] boxShadowTableHover  hover:bg-[#FCFCFC] hover:cursor-pointer hover:border-[#C2B1EF] max-h-[72px]'
    >
      <div className='capitalize w-2/12 text-center  py-[22px] flex items-center justify-center gap-2'>
        <span>{data.username}</span>
        <div
          title='Active'
          className={`w-2 h-2 ${data.is_active ? "bg-green-400" : "bg-red-400"} rounded-full`}
        ></div>
      </div>
      <div className='capitalize w-2/12 text-center  py-[22px]'>{data.forum_name}</div>
      {/* <div className='capitalize w-2/12 text-center  py-[22px]'>{formattedJoinedDate ?? "-"}</div> */}

      <div className='capitalize w-2/12 text-center  py-[22px]'>{data.no_of_posts}</div>
      <div
        className='capitalize w-2/12 text-center flex gap-1 pt-[1rem] items-center justify-center relative'
        onMouseLeave={() => setShowAllOtherCountries(false)}
      >
        {FilteredCountries.length > 0 ? (
          <div className='relative'>
            <div
              className='p-2 leading-[10px] hover:bg-[#C2B1EF] rounded-full bg-[#ECECFF] flex items-center justify-center text-xs relative'
              onClick={handleCountryNumberClick}
            >
              {FilteredCountries.length > 0 ? FilteredCountries.length : null}
            </div>
            {showAllOtherCountries ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseLeave={() => setShowAllOtherCountries(false)}
                className='flex items-center justify-center flex-col absolute z-30 right-0 left-0 mx-auto bottom-[2rem]'
              >
                <div className=' overflow-y-scroll max-h-[10rem] custom-scrollable-container border border-[#C2B1EF] rounded-lg drop-shadow-sm'>
                  {FilteredCountries.map((item) => {
                    return (
                      <div
                        onClick={handleAddCountry}
                        className='w-24 bg-[#ECECFF] py-1 px-2 text-xs hover:bg-[#C2B1EF]'
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='8'
                  viewBox='0 0 22 8'
                  fill='none'
                  className=''
                >
                  <path d='M11 8L21.3923 0.5H0.607696L11 8Z' fill='#C2B1EF' />
                </svg>
              </div>
            ) : null}
          </div>
        ) : (
          "-"
        )}
        {/* {FilteredCountries.length > 1 && (
          <div
            className='bg-[#ECECFF] rounded-full p-1 pt-0 leading-3 flex items-center justify-center  pb-[8px] relative '
            onMouseEnter={() => setShowAllOtherCountries(true)}
            onMouseLeave={() => setShowAllOtherCountries(false)}
          >
            {showAllOtherCountries && (
              <>
                <div className=' absolute -top-20 z-30  overflow-y-scroll max-h-[4rem] custom-scrollable-container border border-[#C2B1EF] rounded-lg drop-shadow-sm'>
                  {FilteredCountries.slice(1).map((item) => {
                    return (
                      <div className='w-24 bg-[#ECECFF] py-1 px-2 text-xs'>
                        {FilteredCountries.length}
                      </div>
                    );
                  })}
                </div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='8'
                  viewBox='0 0 22 8'
                  fill='none'
                  className='absolute -top-4'
                >
                  <path d='M11 8L21.3923 0.5H0.607696L11 8Z' fill='#C2B1EF' />
                </svg>
              </>
            )}
          </div>
        )} */}
        {/* {data.target_countries.length === 0 ? (
          <div className=' col-span-3'>-</div>
        ) : (
          FilteredCountries.map((country) => {
            if (country === null) return;
            return (
              <div
                className='px-1 py-1 rounded-full bg-[#ECECFF] flex items-center justify-center text-xs'
                key={country}
                title={country}
              >
                {LongCountryNames[country] || country}
              </div>
            );
          })
        )} */}
      </div>
      {/* <div className='capitalize w-2/12 text-center  py-[22px]'>{data.no_of_active_days}</div> */}
      <div className='capitalize w-2/12 flex items-start justify  py-[22px]'>
        {data.most_posted_category}
      </div>
      <div className='capitalize w-2/12 text-center  py-[22px]'>{formattedLastPostedDatetime}</div>
    </a>
  );
};

export default UserTableBodyChild;
