import { useEffect, useMemo, useState } from "react";
import ArrowLeft from "../icons/ArrowLeft";
import Skeleton from "react-loading-skeleton";

interface Props {
  currentPage: number;
  setCurrentPage: (param: number) => void;
  max_pages: number;
  isLoading?: boolean;
}
const Pagination = ({ currentPage, setCurrentPage, max_pages, isLoading }: Props) => {
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (pageNumber > 0) {
        setCurrentPage(pageNumber);
      }
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, [pageNumber]);

  const pageNumsToShow = useMemo<Array<number>>(() => { 
    var pages: Array<number> = [];
    console.log('memo', currentPage, max_pages)
    for (var page = Math.max(1, currentPage - 1); page <= Math.min(currentPage + 1, max_pages); ++page) { console.log(page);  pages.push(page); }
    return pages;
  }, [currentPage, isLoading, max_pages])
  
  return (
    <div className=' mt-auto pt-4'>
      <div className='flex gap-8 mx-auto w-fit'>
        <button
          className=' rounded-full border border-[#591DAD] p-4 bg-[#591DAD]'
          onClick={() => {
            currentPage > 1 ? setCurrentPage(currentPage - 1) : "";
          }}
        >
          <ArrowLeft width={16} height={16} stroke='white' />
        </button>
        {isLoading ? (
          <Skeleton className='w-[300px]' containerClassName='flex max-h-[100px] my-auto' />
        ) : (
          <>
            {" "}
            <div
              className={`min-w-[45px] min-h-[45px] p-[13px] border border-[#591DAD]  hover:cursor-pointer rounded-full flex items-center justify-center ${
                currentPage < 3 ? "hidden" : ""
              }`}
              onClick={() => setCurrentPage(1)}
            >
              1
            </div>
            <div
              className={`min-w-[45px] min-h-[45px] p-[13px] border border-[#591DAD]  rounded-full flex items-center justify-center ${
                currentPage < 4 ? "hidden" : ""
              }`}
            >
              ...
            </div>
            {pageNumsToShow.map((val) => {
              return (
                <li
                  key={val}
                  onClick={() => setCurrentPage(val)}
                  className={`min-w-[45px] min-h-[45px] p-[13px] border border-[#591DAD] rounded-full flex items-center justify-center hover:cursor-pointer ${
                    val === currentPage ? "text-white bg-[#591DAD]" : ""
                    } 
                  ${val > currentPage + 1 ? "hidden" : ""} 
                  ${val < currentPage - 1 ? "hidden" : ""}
                  `}
                >
                  {val}
                </li>
              );
            })}
            <div
              className={`min-w-[45px] min-h-[45px] p-[13px] border border-[#591DAD]  rounded-full flex items-center justify-center ${
                currentPage + 2 >= max_pages ? "hidden" : ""
              }`}
            >
              ...
            </div>
            <div
              onClick={() => setCurrentPage(max_pages)}
              className={`min-w-[45px] min-h-[45px] p-[13px] border border-[#591DAD] hover:cursor-pointer rounded-full flex items-center justify-center ${
                currentPage + 2 >= max_pages ? "hidden" : ""
              }`}
            >
              {max_pages}
            </div>
          </>
        )}

        <button
          className=' rounded-full border border-[#591DAD] p-4 bg-[#591DAD]'
          onClick={() => (currentPage === max_pages ? "" : setCurrentPage(currentPage + 1))}
        >
          <ArrowLeft width={16} height={16} stroke='white' className='rotate-180' />
        </button>
        <div className='flex items-center gap-2'>
          <span>Page</span>
          <div>
            <input
              type='text'
              value={pageNumber==0?"":pageNumber}
              className='bg-white border border-slate-200 px-2 py-2 rounded-2xl w-12'
              onFocus={(e) => {
                e.target.select();
              }}
              onChange={(e) => {
                const regex = /^[0-9]*$/;
                if (!regex.test(e.target.value)) return;
                if (Number(e.target.value) > max_pages) return;
                setPageNumber(Number(e.target.value));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
