import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import {
  ResponseActiveUsers,
  ResponseActiveUsersSchemaCountInterface,
  ResponseUniqueUsers,
} from "../types";
import {
  ResponseActiveUsersSchema,
  ResponseActiveUsersSchemaCount,
  ResponseUniqueUsersSchema,
} from "../schema";
import ActiveUsers from "../dummyData/ActiveUsers";
import ActiveUsersCount from "../dummyData/ActiveUsersCount";
import UniqueUsers from "../dummyData/UniqueUsers";
// const endpoint = "http://0.0.0.0:8000/api";
const endpoint = import.meta.env.VITE_API_URL

const isDev = import.meta.env.MODE === "development";

export const fetchActiveUser = async (
  sort_by: {
    by: string;
    order: number;
  }[],
  filter_by: {
    by: string;
    value:
      | string
      | string[]
      | {
          from?: string;
          to?: string;
        };
  }[],
  currentPage: number,
  debouncedSearch: string,
  perPage: number,
): Promise<ResponseActiveUsers | "Server error"> => {
  try {
    const token = Cookies.get("token") ?? "";

    const body: {
      sort_by?: {
        by: string;
        order: number;
      }[];
      filter_by?: {
        by: string;
        value:
          | string
          | string[]
          | {
              from?: string;
              to?: string;
            };
      }[];
    } = {};

    const params: {
      search_key?: string;
      page_num: number;
      items_per_page: number;
    } = {
      page_num: currentPage,
      items_per_page: perPage,
    };

    if (!(sort_by.length === 0)) {
      body.sort_by = body.sort_by || [];
      sort_by.forEach((item) => {
        body.sort_by?.push(item);
      });
    }

    if (!(filter_by.length === 0)) {
      body.filter_by = body.filter_by || [];
      filter_by.forEach((item) => {
        body.filter_by?.push(item);
      });
    }

    if (debouncedSearch.length > 0) {
      params.search_key = debouncedSearch;
    }

    if (isDev) return ActiveUsers;

    const response: AxiosResponse<ResponseActiveUsers> = await axios.post(
      `${endpoint}/v2/private/users`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return ResponseActiveUsersSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchActiveUserData = async (
  sort_by: {
    by: string;
    order: number;
  }[],
  filter_by: {
    by: string;
    value:
      | string
      | string[]
      | {
          from?: string;
          to?: string;
        };
  }[],
  currentPage: number,
  debouncedSearch: string,
  perPage: number,
): Promise<ResponseActiveUsers | undefined> => {
  try {
    const token = Cookies.get("token") ?? "";

    const body: {
      sort_by?: {
        by: string;
        order: number;
      }[];
      filter_by?: {
        by: string;
        value:
          | string
          | string[]
          | {
              from?: string;
              to?: string;
            };
      }[];
    } = {};

    const params: {
      search_key?: string;
      page_num: number;
      items_per_page: number;
    } = {
      page_num: currentPage,
      items_per_page: perPage,
    };

    if (!(sort_by.length === 0)) {
      body.sort_by = body.sort_by || [];
      sort_by.forEach((item) => {
        body.sort_by?.push(item);
      });
    }

    if (!(filter_by.length === 0)) {
      body.filter_by = body.filter_by || [];
      filter_by.forEach((item) => {
        body.filter_by?.push(item);
      });
    }

    if (debouncedSearch.length > 0) {
      params.search_key = debouncedSearch;
    }

    if (isDev) return ActiveUsers;

    const response: AxiosResponse<ResponseActiveUsers> = await axios.post(
      `${endpoint}/v3/private/users`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return ResponseActiveUsersSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const fetchActiveUserDataCount = async (
  sort_by: {
    by: string;
    order: number;
  }[],
  filter_by: {
    by: string;
    value:
      | string
      | string[]
      | {
          from?: string;
          to?: string;
        };
  }[],
  currentPage: number,
  debouncedSearch: string,
  perPage: number,
): Promise<ResponseActiveUsersSchemaCountInterface | undefined> => {
  try {
    const token = Cookies.get("token") ?? "";

    const body: {
      sort_by?: {
        by: string;
        order: number;
      }[];
      filter_by?: {
        by: string;
        value:
          | string
          | string[]
          | {
              from?: string;
              to?: string;
            };
      }[];
    } = {};

    const params: {
      search_key?: string;
      page_num: number;
      items_per_page: number;
    } = {
      page_num: currentPage,
      items_per_page: perPage,
    };

    if (!(sort_by.length === 0)) {
      body.sort_by = body.sort_by || [];
      sort_by.forEach((item) => {
        body.sort_by?.push(item);
      });
    }

    if (!(filter_by.length === 0)) {
      body.filter_by = body.filter_by || [];
      filter_by.forEach((item) => {
        body.filter_by?.push(item);
      });
    }

    if (debouncedSearch.length > 0) {
      params.search_key = debouncedSearch;
    }

    if (isDev) return ActiveUsersCount;

    const response: AxiosResponse<ResponseActiveUsers> = await axios.post(
      `${endpoint}/v3/private/users/count`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return ResponseActiveUsersSchemaCount.parse(response.data);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const fetchIndividualUser = async (username: string, forum_name: string) => {
  console.log(UniqueUsers);
  if (isDev) return ResponseUniqueUsersSchema.parse(UniqueUsers);
  try {
    const token = Cookies.get("token") ?? "";

    const params = {
      username,
      forum_name,
    };
    const response: AxiosResponse<ResponseUniqueUsers> = await axios.get(
      `${endpoint}/private/user`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return ResponseUniqueUsersSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};
