import { boolean, z } from "zod";
import { DrugPostItem } from "./drugPost";

export const UserTableItem = z.object({
  total_posts: z.number().nullable().default(0),
  latest_posttitle: z.string().nullable().default(""),
  latest_postref: z.string().nullable().default(""),
  // vendor_reviews: z.number().nullable().default(0),
  vendor_reviews: z.union([z.string(), z.number()]).nullable().default(0),
  marketplace_name: z.string().nullable().default(""),
  vendor_name: z.string().nullable().default(""),
  latest_postdate: z.string().nullable().default(""),
  formattedDate: z.string().nullable().default(""),
});

export const UseTableItemSchema = z.object({
  data: z.array(UserTableItem),
  metadata: z.object({
    total: z.number().default(0),
    page: z.number().default(0),
    per_page: z.number().default(0),
    has_next: z.boolean().nullable().default(false),
    has_previous: z.boolean().nullable().default(false),
  }),
});

export const DrugUserUniquePageSchema = z.object({
  _id: z.string().nullable().default(""),
  last24HoursCount: z.number().nullable().default(0),
  lastWeekCount: z.number().nullable().default(0),
  lastMonthCount: z.number().nullable().default(0),
  vendor_rating: z.string().nullable().default(""),
  vendor_url: z.string().nullable().default(""),
  // vendor_reviews: z.string().nullable().default(""),
  vendor_reviews: z.union([z.string(), z.number()]).nullable().default(0),
  latest_post: z.string().nullable().default(""),
  latest_posttile: z.string().nullable().default(""),
  latest_postref: z.string().nullable().default(""),
  drugCategoryDictionary: z.record(z.number()),
  subdrugCategoryDictionary: z.record(z.number()),
  shipping_fromDictionary: z.record(z.number()),
  shipping_toDictionary: z.record(z.number()),
});

export const DrugUserListingResponseSchema = z.object({
  data: z.array(DrugPostItem),
  metadata: z.object({
    total: z.number().default(0),
    page: z.number().default(0),
    per_page: z.number().default(0),
    has_next: z.boolean().nullable().default(false),
    has_previous: z.boolean().nullable().default(false),
  }),
});
