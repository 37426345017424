/* eslint-disable @typescript-eslint/no-explicit-any */
import Skeleton from "react-loading-skeleton";
import { ResponseActiveUsersSchemaCountInterface } from "../../../types";
import NumberOfEntries from "./NumberOfEntries";
import Pagination from "./Pagination";

interface Props {
  data: ResponseActiveUsersSchemaCountInterface | undefined;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>> | ((number: number) => void);
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading?: boolean;
}

const PaginationWithPageInfoPosts = ({
  data,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  isLoading,
}: Props) => {
  
  const startResult = perPage * (currentPage - 1) + 1;
  const endResult = Math.min(perPage * currentPage, data?.total_results ?? 0);

  return (
    <div className='flex justify-between'>
      {isLoading ? (
        <Skeleton className='w-[200px]' containerClassName='flex max-h-[50px] my-auto' />
      ) : (
        <div className='text-gray-400 mt-4'>
          Results {startResult} - {endResult} out of {data?.total_results} results
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        max_pages={data ? Math.ceil((data?.total_results ?? 1) / perPage) : 0}
        isLoading={isLoading}
      />
      <div className='mt-4'>
        <NumberOfEntries
          currentNumberOfEntries={perPage}
          setCurrentNumberOfEntries={setPerPage}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PaginationWithPageInfoPosts;
