import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { PostItemsResponse, PostUniqueResponse } from "../types";
import { z } from "zod";
// const endpoint = "http://0.0.0.0:8000/api";
const endpoint = import.meta.env.VITE_API_URL;
import Posts from "../dummyData/Posts";
import UserPosts from "../dummyData/UserPosts";
import PostPage from "../dummyData/PostPage";
import PostPageComments from "../dummyData/postComments";
import PostCount from "../dummyData/PostsCount";
import allPossiblePostCategories from "../dummyData/allPossiblePostCategories";
import {
  PostItemsResponseSchema,
  PostIndividualPostSchema,
  PostIndividualCommentsSchema,
  PostIndividualCommentsResponse,
  PostItemsSchema,
  RealPostItemResponseSchema,
  ResponseActiveUsersSchemaCount,
  ResponsePostschemaCount,
} from "../schema";

const isDev = import.meta.env.MODE === "development";

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const fetchPosts = async (
  sort_by: {
    by: string;
    order: number;
  }[],
  filter_by: {
    by: string;
    value:
      | string
      | string[]
      | {
          from?: string;
          to?: string;
        };
  }[],
  currentPage: number,
  debouncedSearch: string,
  perPage: number,
) => {
  try {
    // await wait(5000);
    const token = Cookies.get("token") ?? "";

    const body: {
      sort_by?: {
        by: string;
        order: number;
      }[];
      filter_by?: {
        by: string;
        value:
          | string
          | string[]
          | {
              from?: string;
              to?: string;
            };
      }[];
    } = {};

    const params: {
      page_num: number;
      items_per_page: number;
      search_key?: string;
    } = {
      page_num: currentPage,
      items_per_page: perPage,
    };

    if (debouncedSearch.length > 0) {
      params.search_key = debouncedSearch;
    }

    if (sort_by.length > 0) {
      body.sort_by = sort_by;
    }
    if (!(filter_by.length === 0)) {
      body.filter_by = body.filter_by || [];
      filter_by.forEach((item) => {
        body.filter_by?.push(item);
      });
    }

    if (isDev) return PostItemsResponseSchema.parse(Posts);

    const response: AxiosResponse<PostItemsResponse> = await axios.post(
      `${endpoint}/private/posts`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return PostItemsResponseSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchPostsData = async (
  sort_by: {
    by: string;
    order: number;
  }[],
  filter_by: {
    by: string;
    value:
      | string
      | string[]
      | {
          from?: string;
          to?: string;
        };
  }[],
  currentPage: number,
  debouncedSearch: string,
  perPage: number,
) => {
  try {
    const token = Cookies.get("token") ?? "";

    const body: {
      sort_by?: {
        by: string;
        order: number;
      }[];
      filter_by?: {
        by: string;
        value:
          | string
          | string[]
          | {
              from?: string;
              to?: string;
            };
      }[];
    } = {};

    const params: {
      page_num: number;
      items_per_page: number;
      search_key?: string;
    } = {
      page_num: currentPage,
      items_per_page: perPage,
    };

    if (sort_by.length > 0) {
      body.sort_by = sort_by;
    }
    if (!(filter_by.length === 0)) {
      body.filter_by = body.filter_by || [];
      filter_by.forEach((item) => {
        body.filter_by?.push(item);
      });
    }

    if (debouncedSearch.length > 0) {
      params.search_key = debouncedSearch;
    }
    if (isDev) return RealPostItemResponseSchema.parse(Posts);

    const response: AxiosResponse<PostItemsResponse> = await axios.post(
      `${endpoint}/v2/private/posts`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return RealPostItemResponseSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const fetchPostsDataCount = async (
  sort_by: {
    by: string;
    order: number;
  }[],
  filter_by: {
    by: string;
    value:
      | string
      | string[]
      | {
          from?: string;
          to?: string;
        };
  }[],
  currentPage: number,
  debouncedSearch: string,
  perPage: number,
) => {
  try {
    const token = Cookies.get("token") ?? "";

    const body: {
      sort_by?: {
        by: string;
        order: number;
      }[];
      filter_by?: {
        by: string;
        value:
          | string
          | string[]
          | {
              from?: string;
              to?: string;
            };
      }[];
    } = {};

    const params: {
      page_num: number;
      items_per_page: number;
      search_key?: string;
    } = {
      page_num: currentPage,
      items_per_page: perPage,
    };

    if (sort_by.length > 0) {
      body.sort_by = sort_by;
    }
    if (!(filter_by.length === 0)) {
      body.filter_by = body.filter_by || [];
      filter_by.forEach((item) => {
        body.filter_by?.push(item);
      });
    }

    if (debouncedSearch.length > 0) {
      params.search_key = debouncedSearch;
    }
    if (isDev) return ResponsePostschemaCount.parse(PostCount);

    const response: AxiosResponse<PostItemsResponse> = await axios.post(
      `${endpoint}/v2/private/posts/count`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return ResponsePostschemaCount.parse(response.data);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const fetchUserPosts = async (
  sort_by: {
    by: string;
    order: number;
  }[],
  filter_by: {
    by: string;
    value:
      | string
      | string[]
      | {
          from?: string;
          to?: string;
        };
  }[],
  currentPage: number,
  debouncedSearch: string,
  perPage: number,
) => {
  try {
    const token = Cookies.get("token") ?? "";

    const body: {
      sort_by?: {
        by: string;
        order: number;
      }[];
      filter_by?: {
        by: string;
        value:
          | string
          | string[]
          | {
              from?: string;
              to?: string;
            };
      }[];
    } = {};

    const params: {
      page_num: number;
      items_per_page: number;
      search_key?: string;
    } = {
      page_num: currentPage,
      items_per_page: perPage,
    };

    if (debouncedSearch.length > 0) {
      params.search_key = debouncedSearch;
    }

    if (sort_by.length > 0) {
      body.sort_by = sort_by;
    }
    if (!(filter_by.length === 0)) {
      body.filter_by = body.filter_by || [];
      filter_by.forEach((item) => {
        body.filter_by?.push(item);
      });
    }

    if (isDev) return PostItemsResponseSchema.parse(UserPosts);

    const response: AxiosResponse<PostItemsResponse> = await axios.post(
      `${endpoint}/v2/private/posts`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return PostItemsResponseSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchIndividualPost = async (thread_id: string) => {
  if (isDev) return PostIndividualPostSchema.parse(PostPage);
  try {
    const token = Cookies.get("token") ?? "";

    const params = {
      thread_id: thread_id,
    };

    // const response: AxiosResponse<ResponseIndividualPost> = await axios.get(
    const response: AxiosResponse<PostUniqueResponse> = await axios.get(
      `${endpoint}/private/post`,

      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return PostIndividualPostSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchIndividualPostComments = async (thread_id: string, pageNum: number) => {
  // if (isDev) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(PostIndividualCommentsSchema.parse(PostPageComments));
  //     }, 1000);
  //   });
  // }
  if (isDev) return PostIndividualCommentsSchema.parse(PostPageComments);
  try {
    const token = Cookies.get("token") ?? "";

    const params = {
      page_num: pageNum,
      items_per_page: 10,
      thread_id: thread_id,
    };

    const response: AxiosResponse<PostIndividualCommentsResponse> = await axios.post(
      `${endpoint}/private/comments`,
      {},
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return PostIndividualCommentsSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};
export const fetchAllPostCategories = async (): Promise<string[] | "Server error"> => {
  if (isDev) return allPossiblePostCategories;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<string[]> = await axios.get(`${endpoint}/private/key/category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};
