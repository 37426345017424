import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import { useActiveUsers, userDetails } from "../../../store";
import { ResponseActiveUsersData } from "../../../types";
import { fetchActiveUser, fetchActiveUserData, fetchActiveUserDataCount } from "../../../utils";
import CountrySearch from "../../components/Global/Filters/CountrySearch";
import DateSearch from "../../components/Global/Filters/DateSearch";
import PaginationWithPageInfoPosts from "../../components/Global/PaginationWithPageInfoPosts";
import BasicLayout from "../../components/layout/BasicLayout";
import ForumSearch from "../../components/pages/ActiveUsers/ForumSearch";
import TableHeaderv2 from "../../components/pages/ActiveUsers/TableHeaderV2";
import UserTableBodyChild from "../../components/pages/ActiveUsers/UserTableBodyChild";
import PaginationWithPageInfoPostsAlternative from "../../components/Global/PaginationWithPageInfoPostsAlternative";

interface SortOrderItem {
  by: string;
  order: number;
}

interface FilterOrderItem {
  by: string;
  value:
    | string[]
    | {
        from?: string;
        to?: string;
      };
}

const ActiveUsers = () => {
  const [showFilter, setShowFilter] = useState(false);

  // const [filters, setFilters] = useState([]);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");
  const currentPage = useActiveUsers((state) => state.currentPage);
  const setCurrentPage = useActiveUsers((state) => state.setCurrentPage);
  const [sortOrder, setSortOrder] = useState<SortOrderItem[]>([
    {
      by: "last_posted_datetime",
      order: -1,
    },
  ]);
  const [filterOrder, setFilterOrder] = useState<FilterOrderItem[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [perPage, setPerPage] = useState<number>(10);

  const DateFitlerValues: {
    from?: string;
    to?: string;
  } = useMemo(() => {
    const x: {
      from?: string;
      to?: string;
    } = {};
    if (dateFrom !== null) {
      x.from = format(parseISO(dateFrom.toISOString()), "yyyy-MM-dd");
    }
    if (dateTo !== null) {
      x.to = format(parseISO(dateTo.toISOString()), "yyyy-MM-dd");
    }

    return x;
  }, [dateFrom, dateTo]);

  const containerFilterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  // const { data, isFetching, isLoading } = useQuery({
  //   queryKey: [
  //     "users",
  //     debouncedSearch,
  //     currentPage,
  //     ...sortOrder,
  //     ...filterOrder,
  //     perPage,
  //     selectedCountry,
  //     dateFrom,
  //     dateTo,
  //   ],
  //   queryFn: () => fetchActiveUser(sortOrder, filterOrder, currentPage, debouncedSearch, perPage),
  //   staleTime: 24 * 60 * 60 * 60,
  // });

  const { data: realData, isFetching: realIsFetching } = useQuery({
    queryKey: [
      "usersData",
      debouncedSearch,
      currentPage,
      ...sortOrder,
      ...filterOrder,
      perPage,
      selectedCountry,
      dateFrom,
      dateTo,
    ],
    queryFn: () =>
      fetchActiveUserData(sortOrder, filterOrder, currentPage, debouncedSearch, perPage),
    staleTime: 24 * 60 * 60 * 60,
  });
  const { data: realDataCount, isLoading: realDataLoading } = useQuery({
    queryKey: [
      "usersDataCount",
      debouncedSearch,
      currentPage,
      ...sortOrder,
      ...filterOrder,
      perPage,
      selectedCountry,
      dateFrom,
      dateTo,
    ],
    queryFn: () =>
      fetchActiveUserDataCount(sortOrder, filterOrder, currentPage, debouncedSearch, perPage),
    staleTime: 24 * 60 * 60 * 60,
  });

  //ADD SELECTED COUNTRY TO FILTER ORDER
  useEffect(() => {
    if (selectedCountry.length > 0) {
      if (Array.isArray(filterOrder)) {
        if (filterOrder.some((x) => x.by === "target_countries")) {
          setFilterOrder((prev) => {
            return prev.map((x) => {
              return x.by === "target_countries" ? { ...x, value: selectedCountry } : x;
            });
          });
        } else {
          setFilterOrder((prev) => {
            return [...prev, { by: "target_countries", value: selectedCountry }];
          });
        }
      } else {
        setFilterOrder([{ by: "target_countries", value: selectedCountry }]);
      }
    } else {
      setFilterOrder((prev) => {
        return prev.filter((item) => {
          if (item.by !== "target_countries") {
            return item;
          }
        });
      });
    }
  }, [selectedCountry]);

  //ADD DATE INTO FILTER ORDER
  useEffect(() => {
    if (Object.keys(DateFitlerValues).length === 0) {
      const x = filterOrder.filter((y) => {
        return y.by !== "last_posted_datetime";
      });
      setFilterOrder(x);
    } else {
      if (
        filterOrder.some((x) => {
          return x.by === "last_posted_datetime";
        })
      ) {
        const x = filterOrder.map((item) => {
          if (item.by === "last_posted_datetime") {
            return { ...item, value: DateFitlerValues };
          }
          return item;
        });
        setFilterOrder(x);
      } else {
        const x = [
          ...filterOrder,
          {
            by: "last_posted_datetime",
            value: DateFitlerValues,
          },
        ];
        setFilterOrder(x);
      }
    }
  }, [DateFitlerValues]);

  //DEBOUNCED SEARCH
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  //REMOVE POPUP FILTER
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerFilterRef.current &&
        !containerFilterRef.current.contains(e.target as Node) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(e.target as Node)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerFilterRef]);

  //RESET PAGE NUMBER
  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch, sortOrder, filterOrder, setCurrentPage, perPage]);
  // if (!isAuthenticated) return;

  // if (data === "Server error") return;

  return (
    <BasicLayout>
      <div className='flex '>
        <h1 className='text-[36px] font-sans font-semibold'>
          <span className='text-[#E71787]'>User</span> Profiles
        </h1>
        <div className='relative ml-auto'>
          <input
            type='text'
            className='rounded-full border border-[#D4D4D4] bg-[#FAFAFA] py-[12px] pl-[60px] w-[500px]'
            placeholder='Search Username'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            src='/search.svg'
            className='w-[20px] h-[20px] absolute left-4 top-0 bottom-0 my-auto'
            alt=''
          />
        </div>

        <div className='relative ' ref={filterButtonRef}>
          {showFilter ? (
            <img
              src='/filterFilled.svg'
              alt=''
              className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
              onClick={() => setShowFilter((prev) => !prev)}
            />
          ) : (
            <img
              src='/filter.svg'
              alt=''
              className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
              onClick={() => setShowFilter((prev) => !prev)}
            />
          )}
          {showFilter ? (
            <div
              className='filterShadow bg-[#FCF6FF] absolute top-8  right-0 px-[21px] rounded-[18px] pt-[50px] pb-[50px] min-w-[300px] z-20'
              ref={containerFilterRef}
            >
              <img
                src='cancel.svg'
                alt=''
                className='absolute top-[26px] right-[30px] w-[14px] h-[14px] hover:cursor-pointer'
                onClick={() => setShowFilter(false)}
              />

              <div className='relative'>
                <ForumSearch setFilterOrder={setFilterOrder} filterOrder={filterOrder} />
                <CountrySearch
                  values={selectedCountry}
                  handler={setSelectedCountry}
                  title='Countries'
                />
                <DateSearch
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                />
                {/* <LastPostedDateSearch dateFrom={dateFrom} setDateFrom={setDateFrom} /> */}
                {/* <FilterSearch header="Threads" filters={filters} />
                <FilterSearch header="Keywords" filters={filters} />
                <FilterSearch header="Categories" filters={filters} />
                <DateSearch /> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {realIsFetching ? (
        <div className='mt-[40px]'>
          <div>
            <Skeleton className='h-14' count={12} />
          </div>
        </div>
      ) : (
        <>
          <div className='bg-[#F9F9FF] mt-[40px] rounded-[12px]'>
            <TableHeaderv2 setSortOrder={setSortOrder} sortOrder={sortOrder} />

            {realData?.data.length == 0 ? (
              <div className='w-full flex items-center justify-center h-12'>No data available</div>
            ) : (
              realData?.data.map((item: ResponseActiveUsersData) => {
                return (
                  <UserTableBodyChild
                    data={item}
                    key={uuidv4()}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                  />
                );
              })
            )}
          </div>

          <PaginationWithPageInfoPostsAlternative
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={realDataCount}
            perPage={perPage}
            setPerPage={setPerPage}
            isLoading={realDataLoading}
          />
        </>
      )}
    </BasicLayout>
  );
};

export default ActiveUsers;
