import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useActiveUsers } from "../../../store";
import { ResponseActiveUsersSchemaCountInterface } from "../../../types";
import { fetchPostsData, fetchPostsDataCount } from "../../../utils";
import DateSearch from "../../components/Global/Filters/DateSearch";
import PostCategorySearch from "../../components/Global/Filters/PostCategorySearch";
import PaginationWithPageInfoPostsAlternative from "../../components/Global/PaginationWithPageInfoPostsAlternative";
import BasicLayout from "../../components/layout/BasicLayout";
import ForumSearch from "../../components/pages/ActiveUsers/ForumSearch";
import PostTableBodyChild from "../../components/pages/posts/PostTableBodyChild";
import PostTableHeaderV2 from "../../components/pages/posts/PostTableHeaderV2";

interface SortOrderItem {
  by: string;
  order: number;
}
interface FilterOrderItem {
  by: string;
  value:
    | string[]
    | {
        from?: string;
        to?: string;
      };
}
const Posts = () => {
  console.log('render', ' Posts');
  
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");

  const currentPage = useActiveUsers((state) => state.currentPage);
  const setCurrentPage = useActiveUsers((state) => state.setCurrentPage);

  const [sortOrder, setSortOrder] = useState<SortOrderItem[]>([]);
  const [filterOrder, setFilterOrder] = useState<FilterOrderItem[]>([]);

  const [perPage, setPerPage] = useState<number>(10);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [showFilter, setShowFilter] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [cateogries, setCategories] = useState<string[]>([]);

  const containerFilterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const DateFitlerValues: {
    from?: string;
    to?: string;
  } = useMemo(() => {
    const x: {
      from?: string;
      to?: string;
    } = {};
    if (dateFrom !== null) {
      x.from = format(parseISO(dateFrom.toISOString()), "yyyy-MM-dd");
    }
    if (dateTo !== null) {
      x.to = format(parseISO(dateTo.toISOString()), "yyyy-MM-dd");
    }

    return x;
  }, [dateFrom, dateTo]);

  //ADD SELECTED CATEGORIES TO FILTER ORDER
  useEffect(() => {
    if (cateogries.length > 0) {
      if (Array.isArray(filterOrder)) {
        if (filterOrder.some((x) => x.by === "category")) {
          setFilterOrder((prev) => {
            return prev.map((x) => {
              return x.by === "category" ? { ...x, value: cateogries } : x;
            });
          });
        } else {
          setFilterOrder((prev) => {
            return [...prev, { by: "category", value: cateogries }];
          });
        }
      } else {
        setFilterOrder([{ by: "category", value: cateogries }]);
      }
    } else {
      setFilterOrder((prev) => {
        return prev.filter((item) => {
          if (item.by !== "category") {
            return item;
          }
        });
      });
    }
  }, [cateogries]);

  //RESET PAGE NUMBER
  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch, sortOrder, filterOrder, setCurrentPage, perPage]);

  //ADD DATE INTO FILTER ORDER
  useEffect(() => {
    if (Object.keys(DateFitlerValues).length === 0) {
      const x = filterOrder.filter((y) => {
        return y.by !== "posted_datetime";
      });
      setFilterOrder(x);
    } else {
      if (
        filterOrder.some((x) => {
          return x.by === "posted_datetime";
        })
      ) {
        const x = filterOrder.map((item) => {
          if (item.by === "posted_datetime") {
            return { ...item, value: DateFitlerValues };
          }
          return item;
        });
        setFilterOrder(x);
      } else {
        const x = [
          ...filterOrder,
          {
            by: "posted_datetime",
            value: DateFitlerValues,
          },
        ];
        setFilterOrder(x);
      }
    }
  }, [DateFitlerValues]);

  // const { data, isFetching, isLoading } = useQuery({
  //   queryKey: ["posts", debouncedSearch, currentPage, ...sortOrder, ...filterOrder, perPage],
  //   queryFn: () => fetchPosts(sortOrder, filterOrder, currentPage, debouncedSearch, perPage),
  //   staleTime: 24 * 60 * 60 * 60,
  // });

  const { data: realData, isFetching: realIsFetching } = useQuery({
    queryKey: [
      "fetchPostData",
      debouncedSearch,
      currentPage,
      ...sortOrder,
      ...filterOrder,
      perPage,
    ],
    queryFn: () => fetchPostsData(sortOrder, filterOrder, currentPage, debouncedSearch, perPage),
    staleTime: 24 * 60 * 60 * 60,
  });
  const { data: realDataCount, isLoading: realDataLoading } = useQuery({
    queryKey: [
      "fetchPostDataCount",
      debouncedSearch,
      // ...sortOrder,
      ...filterOrder,
      // currentPage,
      perPage,
    ],
    queryFn: async() =>
       fetchPostsDataCount(sortOrder, filterOrder, currentPage, debouncedSearch, perPage),
    staleTime: 24 * 60 * 60 * 60,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerFilterRef.current &&
        !containerFilterRef.current.contains(e.target as Node) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(e.target as Node)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerFilterRef]);

  // if (!isAuthenticated) return;

  // if (data === "Server error") return;

  return (
    <BasicLayout>
      <div className='flex '>
        <h1 className='text-[36px] font-sans font-semibold'>
          <span className='text-[#E71787]'>Posts</span>
        </h1>
        <div className='relative ml-auto'>
          <input
            type='text'
            className='rounded-full border border-[#D4D4D4] bg-[#FAFAFA] py-[12px] pl-[60px] w-[500px]'
            placeholder='Search posts titles'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            src='/search.svg'
            className='w-[20px] h-[20px] absolute left-4 top-0 bottom-0 my-auto'
            alt=''
          />
        </div>

        <div className='relative ' ref={filterButtonRef}>
          {showFilter ? (
            <img
              src='/filterFilled.svg'
              alt=''
              className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
              onClick={() => setShowFilter((prev) => !prev)}
            />
          ) : (
            <img
              src='/filter.svg'
              alt=''
              className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
              onClick={() => setShowFilter((prev) => !prev)}
            />
          )}
          {showFilter ? (
            <div
              className={`filterShadow bg-[#FCF6FF] absolute top-8  right-0 px-[21px] rounded-[18px] pt-[50px] pb-[50px] min-w-[300px] z-20 `}
              ref={containerFilterRef}
            >
              <img
                src='cancel.svg'
                alt=''
                className='absolute top-[26px] right-[30px] w-[14px] h-[14px] hover:cursor-pointer'
                onClick={() => setShowFilter(false)}
              />

              <div className='relative'>
                <ForumSearch setFilterOrder={setFilterOrder} filterOrder={filterOrder} />
                <PostCategorySearch
                  values={cateogries}
                  handler={setCategories}
                  title={"Categories"}
                />
                <DateSearch
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                />
              </div>
            </div>
          ) : null } 
        </div>
      </div>
      {realIsFetching || realIsFetching ? (
        <div className='mt-[40px]'>
          <div>
            <Skeleton className='h-12' count={10} />
          </div>
        </div>
      ) : (
        <>
          <div className='bg-[#F9F9FF] mt-[40px]'>
            <PostTableHeaderV2 setSortOrder={setSortOrder} sortOrder={sortOrder} />

            {realData?.data.length == 0 ? (
              <div className='w-full flex items-center justify-center h-12'>No data available</div>
            ) : (
              realData?.data.map((item) => {
                return <PostTableBodyChild data={item} />;
              })
            )}
          </div>

          <PaginationWithPageInfoPostsAlternative
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={realDataCount as ResponseActiveUsersSchemaCountInterface}
            perPage={perPage}
            setPerPage={setPerPage}
            isLoading={realDataLoading}
          />
        </>
      )}
    </BasicLayout>
  );
};

export default Posts;
